<template>
  <div class="billDetialForm-container">
    <form-panel
      ref="form"
      :form="form"
      :submitUrl="submitUrl"
      v-bind="submitConfig"
      :footerShow='!payDetail.payStatus'
    >
    <col2-block title="付款联系人">
      <col2-item label="业主姓名" :text="payDetail.userName" />
      <col2-item label="业主电话" :text="payDetail.userPhone" />
      <col2-item label="所属项目" :text="payDetail.communityName" />
      <col2-item label="业主房号" :text="payDetail.ownerRoomName" />
    </col2-block>
    <col2-block title="账单详情">
      <col2-item label="收费项目" :text="payDetail.item" />
      <col2-item label="收费地址" :text="payDetail.itemAddress" />
      <col2-item label="出账月份" :text="`${payDetail.billYear ? payDetail.billYear : ''}年${payDetail.billMonth ? payDetail.billMonth : ''}月`" />
      <col2-item label="计费周期" :text="`${payDetail.billStartTime? payDetail.billStartTime : ''}-${payDetail.billEndTime ? payDetail.billEndTime : ''}`" />
      <col2-item label="单位价格" :text="`${payDetail.price ? payDetail.price : ''}`" />
      <col2-item label="计价单位" :text="payDetail.unit" />
      <col2-item label="面积/用量/数量" :text="`${payDetail.count ? payDetail.count : ''}`" />
      <col2-item label="账单金额" :text="`￥${payDetail.shouldPayMoney ? payDetail.shouldPayMoney : ''}`" />
      <col2-item label="其它途径已缴金额" :text="`￥${payDetail.paidMoney ? payDetail.paidMoney : '0'}`" />
      <col2-item label="缴费状态" :text="`${payDetail.status === '0' ? '已删除' : payDetail.payStatus === 0 ? '待缴费' : '已缴费'}`" />
      <col2-item label="应缴金额" :text="`￥${payDetail.unpaidMoney ? payDetail.unpaidMoney : '0'}`" />
      <col2-item label="备注" :text="payDetail.remark" />
    </col2-block>
    <col2-block title="缴费详情" v-if="payDetail.payStatus === 1">
      <col2-item label="付款单号" :text="payDetail.payNo" />
      <col2-item label="缴费人" :text="payDetail.payUserName" />
      <col2-item label="联系电话" :text="payDetail.payUserPhone" />
      <col2-item label="联系地址" :text="payDetail.payAddress" />
      <col2-item label="实付金额(元)" :text="`￥${payDetail.payMoney ? payDetail.payMoney : ''}`" />
      <col2-item label="缴费时间" :text="payDetail.payTime" />
      <col2-item label="付款方式" :text="payTypeMap[payDetail.payType]" />
      <col2-item label="付款流水号" :text="payDetail.payNum" />
      <col2-item label="发票抬头" :text="payDetail.billInfo" />
    </col2-block>
    <col2-block title="缴费详情" v-if="payDetail.payStatus === 0 && query.updateMoney == 0">
      <el-form-item label="实付金额(元)" prop="paidMoney" :rules="[{ required: true, message: '请填写实付金额(元)', trigger: 'blur' }]">
        <v-input v-model="form.paidMoney"></v-input>
      </el-form-item>
      <el-form-item label="缴费时间" prop="paidTime" :rules="[{ required: true, message: '请填写缴费时间', trigger: 'change' }]">
        <v-datepicker v-model="form.paidTime" :width="180" type="datetime"/>
      </el-form-item>
      <el-form-item label="付款方式" prop="payType" :rules="[{ required: true, message: '请选择付款方式', trigger: 'change' }]">
        <v-select v-model="form.payType" :options="payTypeOps2"></v-select>
      </el-form-item>
      <el-form-item label="付款流水号">
        <v-input v-model="form.payNum"></v-input>
      </el-form-item>
      <el-form-item label="发票抬头">
        <v-input v-model="form.invoiceTitle"></v-input>
      </el-form-item>
      <el-form-item label="缴费人">
        <v-input v-model="form.payUserName"></v-input>
      </el-form-item>
      <el-form-item label="联系电话" :rules="[{ validator: validatePhone, trigger: 'blur' }]" prop="payUserPhone">
        <v-input v-model="form.payUserPhone"></v-input>
      </el-form-item>
      <el-form-item label="联系地址">
        <v-input v-model="form.payUserAddress"></v-input>
      </el-form-item>
    </col2-block>
    <col2-block title="操作详情" v-if="payDetail.payStatus === 0 && query.updateMoney == 1">
      <el-form-item label="修改应付金额为" prop="totalPrice" :rules="[{ required: true, message: '请填写应付金额', trigger: 'blur' }]">
        <v-input v-model="form.totalPrice"></v-input>
      </el-form-item>
      <el-form-item label="操作备注" prop="remark" :rules="[{ required: true, message: '请填写操作备注', trigger: 'blur' }]">
        <v-input v-model="form.remark"></v-input>
      </el-form-item>
    </col2-block>
    </form-panel>
  </div>
</template>
<script>
import {
  getEstateDetailURL,
  updateEstatesPaymentPaidInfoURL,
  updateEstatesPaymentTotalPriceURL
} from './api'
import { Col2Block, Col2Item } from '@/components/bussiness'
import {
  payTypeOps2,
  payTypeMap,
  payStatusMap
} from './map'
import { mobileRegular } from 'common/regular'

export default {
  components: {
    Col2Block,
    Col2Item
  },
  data () {
    return {
      payTypeOps2,
      payTypeMap,
      payStatusMap,
      query: this.$route.query,
      submitUrl: this.$route.query.updateMoney == 0 ? updateEstatesPaymentPaidInfoURL : updateEstatesPaymentTotalPriceURL, // eslint-disable-line
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      form: {},
      payDetail: {},
      postHeader: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
    }
  },
  mounted () {
    if (this.query.updateMoney == '0') { // eslint-disable-line
      this.form = {
        id: this.query.id,
        communityId: this.query.communityId,
        paidMoney: '',
        paidTime: '',
        payType: 0,
        payNum: '',
        invoiceTitle: '',
        payUserPhone: '',
        payUserName: '',
        payUserAddress: ''
      }
    } else {
      this.form = {
        id: this.query.id,
        communityId: this.query.communityId,
        totalPrice: '',
        remark: ''
      }
    }
    this.getDetail()
  },
  methods: {
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      if (value && value.length > 0 && !regExp.test(value)) {
        callback(new Error('手机号码格式不正确'))
      } else {
        callback()
      }
    },
    getDetail () {
      let data = {
        id: this.query.id,
        communityId: this.query.communityId
      }
      this.$axios.post(getEstateDetailURL, data, this.postHeader).then(res => {
        if (res.status === '100') {
          this.payDetail = res.data
        }
      })
    }
  }
}
</script>
