var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "billDetialForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              footerShow: !_vm.payDetail.payStatus,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-block",
            { attrs: { title: "付款联系人" } },
            [
              _c("col2-item", {
                attrs: { label: "业主姓名", text: _vm.payDetail.userName },
              }),
              _c("col2-item", {
                attrs: { label: "业主电话", text: _vm.payDetail.userPhone },
              }),
              _c("col2-item", {
                attrs: { label: "所属项目", text: _vm.payDetail.communityName },
              }),
              _c("col2-item", {
                attrs: { label: "业主房号", text: _vm.payDetail.ownerRoomName },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "账单详情" } },
            [
              _c("col2-item", {
                attrs: { label: "收费项目", text: _vm.payDetail.item },
              }),
              _c("col2-item", {
                attrs: { label: "收费地址", text: _vm.payDetail.itemAddress },
              }),
              _c("col2-item", {
                attrs: {
                  label: "出账月份",
                  text: `${
                    _vm.payDetail.billYear ? _vm.payDetail.billYear : ""
                  }年${
                    _vm.payDetail.billMonth ? _vm.payDetail.billMonth : ""
                  }月`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "计费周期",
                  text: `${
                    _vm.payDetail.billStartTime
                      ? _vm.payDetail.billStartTime
                      : ""
                  }-${
                    _vm.payDetail.billEndTime ? _vm.payDetail.billEndTime : ""
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "单位价格",
                  text: `${_vm.payDetail.price ? _vm.payDetail.price : ""}`,
                },
              }),
              _c("col2-item", {
                attrs: { label: "计价单位", text: _vm.payDetail.unit },
              }),
              _c("col2-item", {
                attrs: {
                  label: "面积/用量/数量",
                  text: `${_vm.payDetail.count ? _vm.payDetail.count : ""}`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "账单金额",
                  text: `￥${
                    _vm.payDetail.shouldPayMoney
                      ? _vm.payDetail.shouldPayMoney
                      : ""
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "其它途径已缴金额",
                  text: `￥${
                    _vm.payDetail.paidMoney ? _vm.payDetail.paidMoney : "0"
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "缴费状态",
                  text: `${
                    _vm.payDetail.status === "0"
                      ? "已删除"
                      : _vm.payDetail.payStatus === 0
                      ? "待缴费"
                      : "已缴费"
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "应缴金额",
                  text: `￥${
                    _vm.payDetail.unpaidMoney ? _vm.payDetail.unpaidMoney : "0"
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: { label: "备注", text: _vm.payDetail.remark },
              }),
            ],
            1
          ),
          _vm.payDetail.payStatus === 1
            ? _c(
                "col2-block",
                { attrs: { title: "缴费详情" } },
                [
                  _c("col2-item", {
                    attrs: { label: "付款单号", text: _vm.payDetail.payNo },
                  }),
                  _c("col2-item", {
                    attrs: { label: "缴费人", text: _vm.payDetail.payUserName },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "联系电话",
                      text: _vm.payDetail.payUserPhone,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "联系地址",
                      text: _vm.payDetail.payAddress,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "实付金额(元)",
                      text: `￥${
                        _vm.payDetail.payMoney ? _vm.payDetail.payMoney : ""
                      }`,
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "缴费时间", text: _vm.payDetail.payTime },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "付款方式",
                      text: _vm.payTypeMap[_vm.payDetail.payType],
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "付款流水号", text: _vm.payDetail.payNum },
                  }),
                  _c("col2-item", {
                    attrs: { label: "发票抬头", text: _vm.payDetail.billInfo },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.payDetail.payStatus === 0 && _vm.query.updateMoney == 0
            ? _c(
                "col2-block",
                { attrs: { title: "缴费详情" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "实付金额(元)",
                        prop: "paidMoney",
                        rules: [
                          {
                            required: true,
                            message: "请填写实付金额(元)",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.paidMoney,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "paidMoney", $$v)
                          },
                          expression: "form.paidMoney",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "缴费时间",
                        prop: "paidTime",
                        rules: [
                          {
                            required: true,
                            message: "请填写缴费时间",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: { width: 180, type: "datetime" },
                        model: {
                          value: _vm.form.paidTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "paidTime", $$v)
                          },
                          expression: "form.paidTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "付款方式",
                        prop: "payType",
                        rules: [
                          {
                            required: true,
                            message: "请选择付款方式",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.payTypeOps2 },
                        model: {
                          value: _vm.form.payType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "payType", $$v)
                          },
                          expression: "form.payType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款流水号" } },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.payNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "payNum", $$v)
                          },
                          expression: "form.payNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票抬头" } },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.invoiceTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "invoiceTitle", $$v)
                          },
                          expression: "form.invoiceTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "缴费人" } },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.payUserName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "payUserName", $$v)
                          },
                          expression: "form.payUserName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "联系电话",
                        rules: [
                          { validator: _vm.validatePhone, trigger: "blur" },
                        ],
                        prop: "payUserPhone",
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.payUserPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "payUserPhone", $$v)
                          },
                          expression: "form.payUserPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系地址" } },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.payUserAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "payUserAddress", $$v)
                          },
                          expression: "form.payUserAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.payDetail.payStatus === 0 && _vm.query.updateMoney == 1
            ? _c(
                "col2-block",
                { attrs: { title: "操作详情" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "修改应付金额为",
                        prop: "totalPrice",
                        rules: [
                          {
                            required: true,
                            message: "请填写应付金额",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.totalPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "totalPrice", $$v)
                          },
                          expression: "form.totalPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "操作备注",
                        prop: "remark",
                        rules: [
                          {
                            required: true,
                            message: "请填写操作备注",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }